<template>
    <div class="action-refund-status">
        <div class="profile-container">
            <div class="profile-container-left">
                <img class="photo" :src="user.photo_url" />
                <div>
                    <div class="name f-medium" v-html="`${nameOrNick}, ${$options.filters.asAge(user.birthday)}`" />
                    <div class="sub">
                        <div class="location">{{ user.region || '' }}</div>
                        <div class="bar" v-html="' | '"></div>
                        <div class="job">{{ user.job || '' }}</div>
                    </div>
                </div>
            </div>
            <div class="functions flex-row flex-wrap">
                <ChatButton class="button recommended" :user="user" :source_id="$store.getters.chat.user.id" />
            </div>
        </div>
        <div class="divider" />
        <div class="checked f-medium" v-html="refundChecked" />
        <div class="info">{{ nameOrNick }}님의 환급 신청</div>
        <div v-if="this.content.refund_reason_code" class="status f-medium" v-html="refundStatus" />
    </div>
</template>
<script>
export default {
    name: 'ActionRefundStatus',
    props: ['message'],
    data: () => ({
        clickedReject: false,
        clickedContact: false,
        clickedMeet: false,
        refundReasons: [],
    }),
    computed: {
        chat() {
            return this.$store.getters.chat
        },
        content() {
            return this.$mustParse(this.message.content)
        },
        user() {
            const user = this.content.user
            const chatId = (this.$getChat(user.id) || {}).id
            if (chatId) user.$$chatId = chatId
            return user
        },
        nameOrNick() {
            if (this.user.name) return this.user.name
            return this.user.nickname
        },
        refundStatus() {
            if (this.content.refund_reason_code === 'reject') return '“연락, 만남 의사 없어요”'
            else if (this.content.refund_reason_code === 'contact') return '“아직 연락하고 있어요”'
            else if (this.content.refund_reason_code === 'meet') return '“이미 만남을 가졌어요”'
            else return '“아직 연락하고 있어요”'
        },
        refundChecked() {
            if (this.content.is_checked) return `연락, 만남 의사 확인 완료`
            else return `연락, 만남 의사 확인 중`
        },
    },
}
</script>
<style lang="scss" scoped>
.action-refund-status {
    padding-top: 14px;
    padding-bottom: 14px;
    width: calc(100% - 8px);

    .divider {
        width: 100%;
        height: 1px;
        background-color: $grey-02;
        margin-top: 8px;
        margin-bottom: 12px;
    }
    .profile-container {
        padding: 0 12px;
        display: flex;
        justify-content: space-between;

        .profile-container-left {
            display: flex;
            align-items: center;

            .photo {
                width: 44px;
                height: 44px;
                border-radius: 17.6px;
                border: solid 1px $grey-02;
                margin-right: 12px;
            }
            .name {
                font-size: 14px;
                height: 20px;
                color: black;
            }
            .sub {
                display: flex;
                font-size: 12px;
                .job,
                .location {
                    height: 18px;
                    color: $grey-07;
                }
                .bar {
                    color: $grey-04;
                    margin-left: 4px;
                    margin-right: 4px;
                }
            }
        }

        //::v-deep .chat-button {
        //    //flex: 1;
        //    align-self: center;
        //}
    }
    .checked {
        padding: 0 12px;
        font-size: 16px;
        color: black;
        margin-bottom: 8px;
        line-height: normal;
        letter-spacing: -0.2px;
    }
    .info {
        padding: 0 12px;
        font-size: 12px;
        color: $grey-07;
        margin-bottom: 12px;
    }
    .status {
        margin: 4px 12px 12px 12px;
        border: solid 1px $grey-02;
        border-radius: 8px;
        color: $purple-primary;
        font-size: 14px;
        padding: 8px;
        text-align: center;
    }
}
</style>
